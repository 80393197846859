
#id64a1f35ea52ebcacc7a8261e {
  #ia1f {
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#6462a8 0%, #6462a8 100%); 
} 
#i24u {
padding : 10px 8% 10px 8%; 
min-height : 100px; 
} 
#ibpt {
padding : 70px 10px 100px 10px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#6066ad 0%, #6066ad 100%); 
} 
#imbi {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
} 
#ibno {
padding : 10px; 
min-height : 100px; 
width : 70%; 
display : flex; 
align-items : center; 
} 
#iynmw {
padding : 10px; 
min-height : 100px; 
width : 30%; 
display : flex; 
align-items : center; 
justify-content : space-around; 
} 
#i108e {
color : black; 
} 
#ie17s {
color : #d983a6; 
padding : 5px; 
height : auto; 
display : block; 
} 
#ioegt {
width : auto; 
color : #ecf9ff; 
font-family : Tahoma, Geneva, sans-serif; 
font-weight : 700; 
font-size : 14px; 
} 
#ins3p {
width : auto; 
color : #ecf9ff; 
font-family : Tahoma, Geneva, sans-serif; 
font-weight : 700; 
font-size : 14px; 
} 
#is0ec {
color : #d983a6; 
padding : 5px; 
height : auto; 
display : block; 
} 
#i19l7 {
width : auto; 
color : #ecf9ff; 
font-family : Tahoma, Geneva, sans-serif; 
font-weight : 700; 
font-size : 14px; 
} 
#ikkcj {
color : #d983a6; 
padding : 5px; 
height : auto; 
display : block; 
} 
#i4by9 {
padding : 10px 8% 90px 8%; 
min-height : 100px; 
display : block; 
flex-wrap : wrap; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f0f4ff 0%, #f0f4ff 100%); 
border-radius : 40px 40px 0px 0px; 
height : auto; 
} 
#itlxg {
padding : 10px; 
min-height : 100px; 
margin : 70px 0px 0px 0px; 
} 
#il755 {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#iwc4n {
padding : 10px; 
display : block; 
font-family : Tahoma, Geneva, sans-serif; 
font-size : 40px; 
font-weight : 700; 
text-align : center; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f0f4ff 0%, #f0f4ff 100%); 
border-radius : 40px 40px 40px 40px; 
} 
#il9wr2 {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-around; 
} 
#io9efz {
color : black; 
justify-content : space-around; 
align-items : center; 
} 
#ikymgq {
padding : 10px; 
min-height : 100px; 
width : 194px; 
} 
#i1pb9i {
padding : 10px; 
display : block; 
font-family : Tahoma, Geneva, sans-serif; 
font-weight : 600; 
color : #ffffff; 
text-align : left; 
} 
#i4l6j8 {
padding : 10px; 
display : block; 
font-family : Tahoma, Geneva, sans-serif; 
font-weight : 400; 
color : #ffffff; 
} 
#in2cme {
padding : 10px; 
display : block; 
font-family : Tahoma, Geneva, sans-serif; 
font-weight : 600; 
color : #ffffff; 
text-align : left; 
} 
#icwv4n {
padding : 10px; 
display : block; 
font-family : Tahoma, Geneva, sans-serif; 
font-weight : 400; 
color : #ffffff; 
} 
#idswjw {
padding : 10px; 
min-height : 100px; 
width : 438px; 
} 
#i2tl07 {
padding : 10px; 
display : block; 
font-family : Tahoma, Geneva, sans-serif; 
font-weight : 600; 
color : #ffffff; 
text-align : left; 
} 
#iovyfg {
padding : 10px; 
min-height : 100px; 
width : 188px; 
} 
#ih3vwx {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
align-items : center; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#iuktqg {
width : auto; 
display : block; 
height : auto; 
} 
#ibn6mg {
color : black; 
} 
#iwh6wv {
color : black; 
} 
#i5prdq {
width : auto; 
display : block; 
height : auto; 
} 
#izd5ra {
color : black; 
width : 27px; 
height : 26px; 
} 
#io1est {
width : auto; 
display : block; 
height : auto; 
} 
#ivo4oy {
padding : 10px 10px 10px 0px; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
margin : 70px 0px 0px 0px; 
display : flex; 
flex-wrap : wrap; 
height : 562px; 
} 
#iff0og {
min-height : 100px; 
width : 40%; 
display : flex; 
align-items : flex-end; 
} 
#ig6sjz {
padding : 10px; 
min-height : 100px; 
width : 60%; 
display : flex; 
align-items : center; 
} 
#io3epb {
padding : 10px; 
min-height : 200px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#c8d3f0 0%, #c8d3f0 100%); 
width : 100%; 
display : flex; 
justify-content : space-around; 
margin : 0 0 -30px 0; 
} 
#ilpr3f {
color : black; 
width : 362px; 
height : 515px; 
margin : -65px 0px 0px 0px; 
} 
#is8juy {
padding : 10px; 
display : block; 
color : #6066ad; 
font-size : 40px; 
font-family : Tahoma, Geneva, sans-serif; 
font-weight : 700; 
} 
#ichvfw {
padding : 10px; 
min-height : 100px; 
width : 100%; 
} 
#iy7vll {
padding : 10px; 
display : block; 
font-size : 16px; 
color : #6b6f7a; 
font-family : Tahoma, Geneva, sans-serif; 
} 
#iaf6al {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : center; 
} 
#ies19 {
padding : 10px; 
min-height : 100px; 
width : 350px; 
border : 1px solid #6066ad; 
margin : 30px 30px 30px 30px; 
} 
#id3yc {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#iz8pt {
color : black; 
} 
#isyap {
padding : 10px; 
display : block; 
font-size : 24px; 
font-family : Tahoma, Geneva, sans-serif; 
font-weight : 700; 
color : #121212; 
text-align : center; 
} 
#ijlag {
padding : 10px; 
display : block; 
text-align : center; 
font-family : Tahoma, Geneva, sans-serif; 
color : #484848; 
font-size : 16px; 
} 
#iowe1m {
color : black; 
width : 179px; 
height : 191px; 
} 
#iv381z {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#i4k1ae {
padding : 10px; 
display : block; 
font-size : 24px; 
font-family : Tahoma, Geneva, sans-serif; 
font-weight : 700; 
color : #121212; 
text-align : center; 
} 
#iakrka {
padding : 10px; 
display : block; 
text-align : center; 
font-family : Tahoma, Geneva, sans-serif; 
color : #484848; 
font-size : 16px; 
} 
#iuvehk {
padding : 10px; 
min-height : 100px; 
width : 350px; 
border : 1px solid #6066ad; 
margin : 30px 30px 30px 30px; 
} 
#imcp0c {
color : black; 
} 
#i57sva {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#ifwopl {
padding : 10px; 
display : block; 
font-size : 24px; 
font-family : Tahoma, Geneva, sans-serif; 
font-weight : 700; 
color : #121212; 
text-align : center; 
} 
#ipm8ws {
padding : 10px; 
display : block; 
text-align : center; 
font-family : Tahoma, Geneva, sans-serif; 
color : #484848; 
font-size : 16px; 
} 
#i8v3c6 {
padding : 10px; 
min-height : 100px; 
width : 350px; 
border : 1px solid #6066ad; 
margin : 30px 30px 30px 30px; 
} 
#it75ku {
color : black; 
height : 183px; 
} 
#ic336q {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#iy2sel {
padding : 10px; 
display : block; 
font-size : 24px; 
font-family : Tahoma, Geneva, sans-serif; 
font-weight : 700; 
color : #121212; 
text-align : center; 
} 
#ihqfgl {
padding : 10px; 
display : block; 
text-align : center; 
font-family : Tahoma, Geneva, sans-serif; 
color : #484848; 
font-size : 16px; 
} 
#i5g95x {
padding : 10px; 
min-height : 100px; 
width : 350px; 
border : 1px solid #6066ad; 
margin : 30px 30px 30px 30px; 
} 
#imkffj {
color : black; 
} 
#iofcyo {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#ivd9cj {
padding : 10px; 
display : block; 
font-size : 24px; 
font-family : Tahoma, Geneva, sans-serif; 
font-weight : 700; 
color : #121212; 
text-align : center; 
} 
#i8qejf {
padding : 10px; 
display : block; 
text-align : center; 
font-family : Tahoma, Geneva, sans-serif; 
color : #484848; 
font-size : 16px; 
} 
#i07ixz {
padding : 10px; 
min-height : 100px; 
width : 350px; 
border : 1px solid #6066ad; 
margin : 30px 30px 30px 30px; 
} 
#ibfrq1 {
color : black; 
} 
#i3sy1p {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : space-around; 
} 
#i39ocu {
padding : 10px; 
display : block; 
font-size : 24px; 
font-family : Tahoma, Geneva, sans-serif; 
font-weight : 700; 
color : #121212; 
text-align : center; 
} 
#imqqh9 {
padding : 10px; 
display : block; 
text-align : center; 
font-family : Tahoma, Geneva, sans-serif; 
color : #484848; 
font-size : 16px; 
} 
#ivl3ky {
padding : 10px; 
min-height : 100px; 
width : 350px; 
border : 1px solid #6066ad; 
margin : 30px 30px 30px 30px; 
} 
#i28lah {
display : block; 
} 

  @media only screen and (max-width: 992px) {#ibno {
width : 341px; 
}} 
@media only screen and (max-width: 992px) {#iynmw {
width : 395px; 
}} 
@media only screen and (max-width: 992px) {#iwc4n {
font-size : 30px; 
width : auto; 
}} 
@media only screen and (max-width: 992px) {#iff0og {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#is8juy {
font-size : 30px; 
}} 
@media only screen and (max-width: 992px) {#ig6sjz {
width : 50%; 
}} 
@media only screen and (max-width: 992px) {#ilpr3f {
width : 262.031px; 
height : 378px; 
}} 
@media only screen and (max-width: 992px) {#ivo4oy {
height : 431px; 
}} 

  @media only screen and (max-width: 480px) {#iwc4n {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {#iff0og {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ig6sjz {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ivo4oy {
height : auto; 
}} 
@media only screen and (max-width: 480px) {#ilpr3f {
width : 180.406px; 
height : 296px; 
margin : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 480px) {#io3epb {
margin : 0px 0px 0px 0px; 
}} 

}
  